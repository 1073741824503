const auth = {
  namespaced: true,
  state: () => ({
    jwt: null,
    data: null,
    userSub: null,
    userEmail: null,
  }),
  mutations: {
    setTokenData(state, payload) {
      const data = JSON.parse(payload)
      state.data = data
      state.userSub = data.sub
      state.userEmail = data.email
    },
    logout(state) {
      window.sessionStorage.clear()
      state.jwt = null
      state.data = null
      state.userSub = null
      state.userEmail = null
    },
  },
  actions: {}
}

export default auth
