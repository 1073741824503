const chat = {
  namespaced: true,
  state: () => ({
    receivedMessages: [],
    subscribed: false,
  }),
  mutations: {
    updateMessages(state, payload) {
      state.receivedMessages.push(payload)
    },
    setSub(state, val) {
      state.subscribed = val
    },
    clear(state) {
      state.receivedMessages = []
    }
  },
  actions: {
    sub({ commit, rootState }) {
      let msg = JSON.stringify({
        "type": "sub_chat",
      })
      rootState.ws.send(msg)
      commit("setSub", true)
    },
    unsub({ commit, rootState }) {
      let msg = JSON.stringify({
        "type": "unsub_chat",
      })
      rootState.ws.send(msg)
      commit("setSub", false)
      commit("clear")
    },
    send({ rootState }, payload) {
      let msg = JSON.stringify({
        "type": "client_chat",
        "payload": payload,
      })
      rootState.ws.send(msg)
    }
  }
}

export default chat
