const echo = {
  namespaced: true,
  state: () => ({
    receivedMessages: [],
    subscribed: false,
  }),
  mutations: {
    updateMessages(state, payload) {
      state.receivedMessages.push(payload)
    },
    setSub(state, val) {
      state.subscribed = val
    },
  },
  actions: {
    sub({ commit, rootState }) {
      let msg = JSON.stringify({
        "type": "sub_echo",
      })
      rootState.ws.send(msg)
      commit("setSub", true)
    },
    unsub({ commit, rootState }) {
      let msg = JSON.stringify({
        "type": "unsub_echo",
      })
      rootState.ws.send(msg)
      commit("setSub", false)
    },
    send({ rootState }, payload) {
      let msg = JSON.stringify({
        "type": "client_echo",
        "payload": payload,
      })
      rootState.ws.send(msg)
    }
  }
}

export default echo
