const ticker = {
  namespaced: true,
  state: () => ({
    currentTime: null,
    subscribed: false,
  }),
  mutations: {
    updateTime(state, payload) {
      state.currentTime = payload.time
    },
    setSub(state, val) {
      state.subscribed = val
    },
  },
  actions: {
    sub({ commit, rootState }, payload) {
      let msg = JSON.stringify({
        "type": "sub_ticker",
        "payload": {
          "rate": payload.rate ? payload.rate : 1.0
        },
      })
      rootState.ws.send(msg)
      commit("setSub", true)
    },
    unsub({ commit, rootState }) {
      let msg = JSON.stringify({
        "type": "unsub_ticker",
      })
      rootState.ws.send(msg)
      commit("setSub", false)
    }
  }
}

export default ticker
