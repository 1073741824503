<template>
<q-drawer
  v-model="navBarOpen"
  show-if-above
  bordered
  class="bg-grey-2"
>
  <q-list>
    <q-item-label header>Links</q-item-label>
      <q-item
        v-for="item in navItems"
        :key="item.name"
      >
        <q-item-section avatar>
          <q-icon :name="item.icon" />
        </q-item-section>
        <q-item-section>
          <router-link :to="item.link">
            <q-item-label>{{ item.label }}</q-item-label>
            <q-item-label caption> {{ item.caption }}</q-item-label>
          </router-link>
        </q-item-section>
      </q-item>
  </q-list>
</q-drawer>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'

let navItems = [
  {
    label: "Home",
    icon: "home",
    link: "/",
  },
  {
    label: "WebSocket Demo",
    icon: "electrical_services",
    link: "/websocket-demo",
  },
  {
    label: "Hello Demo",
    icon: "format_overline",
    link: "/hello-demo",
  },
  {
    label: "Data Visualization",
    icon: "insights",
    link: "/data-visualization",
  },
]

const props = defineProps({
  navBarOpen: {
    type: Boolean,
    required: true,
  },
})

const navBarOpen = computed({
  get() {
    return props.navBarOpen
  },
  set(value) {
    emit("toggleNavBar", value)
  }
})

const emit = defineEmits(["toggleNavBar"])

</script>
