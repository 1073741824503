import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: '/websocket-demo',
    name: 'WebSocketDemoPage',
    component: () => import('@/views/WebSocketDemoPage.vue'),
  },
  {
    path: '/hello-demo',
    name: 'HelloPage',
    component: () => import('@/views/HelloPage.vue'),
  },
  {
    path: '/data-visualization',
    name: 'DataVisualizationPage',
    component: () => import('@/views/DataVisualizationPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
  },
]
const router = createRouter({
  // 4. Provide the history implementation to use.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

export default router