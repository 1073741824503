import { createStore } from 'vuex'

import auth from "./auth"
import ticker from "./ticker"
import echo from "./echo"
import chat from './chat'
import simpleChart from './simpleChart'


// Create a new store instance.
const store = createStore({
  modules: {
    auth: auth,
    ticker: ticker,
    echo: echo,
    chat: chat,
    simpleChart: simpleChart,
  },
  state: () => ({
    ws: null,
    wsConnected: false,
  }),
  mutations: {
    setupWSConnection (state) {
      const self = this
      const endpoint = `${process.env.VUE_APP_WS_PROTOCOL}://${process.env.VUE_APP_HOST}/ws`
      state.ws = new WebSocket(endpoint)
      state.ws.onopen = function() {
        console.log(`Connected to ${endpoint}...`);
        state.wsConnected = true
      }
      state.ws.onclose = function() {
        console.log(`Closed connection to ${endpoint}.`)
        state.wsConnected = false
      }
      state.ws.onmessage = function(e) {
        try {
          let data = JSON.parse(e.data)
          if (data.type in messageRouter) {
            self.commit(messageRouter[data.type], data.payload)
          }
        } catch (error) {
          console.error("Failed parsing WS message: ", error)
        }
      }
      state.ws.onerror = function() {
        console.log("WebSocket error!")
      }
    },
  },
  actions: {
    send({ state }, payload) {
      state.ws.send(payload)
    },
  }
})

const messageRouter = {
  "server_ticker": "ticker/updateTime",
  "server_echo": "echo/updateMessages",
  "server_chat": "chat/updateMessages",
  "server_simple_chart": "simpleChart/updateData",
}

export default store
