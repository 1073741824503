const simpleChart = {
  namespaced: true,
  state: () => ({
    data: [],
    subscribed: false,
  }),
  mutations: {
    updateData(state, payload) {
      state.data.push(payload)
      if (state.data.length > 1000) {
        state.data.shift()
      }
    },
    clearData(state) {
      state.data = []
    },
    setSub(state, val) {
      state.subscribed = val
    },
  },
  actions: {
    sub({ commit, rootState }) {
      let msg = JSON.stringify({
        "type": "sub_simple_chart",
        "payload": {
          "rate": 2.0,
        }
      })
      rootState.ws.send(msg)
      commit("setSub", true)
    },
    unsub({ commit, rootState }) {
      let msg = JSON.stringify({
        "type": "unsub_simple_chart",
      })
      rootState.ws.send(msg)
      commit("setSub", false)
    },
    reset({ rootState, commit }) {
      let msg = JSON.stringify({
        "type": "unsub_simple_chart",
      })
      rootState.ws.send(msg)
      commit("setSub", false)
      commit("clearData")
    }
  }
}

export default simpleChart
