<template>
  <q-layout view="lHh Lpr lFf">
  <PageHeader
    :navBarOpen="navBarOpen"
    @toggle-nav-bar="navBarOpen=!navBarOpen"
  />
  <NavBar
    :navBarOpen="navBarOpen"
    @toggle-nav-bar="navBarOpen=!navBarOpen"
  />
  <q-page-container>
    <router-view />
  </q-page-container>
  <PageFooter />
</q-layout>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import PageHeader from './components/PageHeader.vue'
import PageFooter from './components/PageFooter.vue'
import NavBar from './components/NavBar.vue'

// init the store
const store = useStore()
store.commit("setupWSConnection")

// set the navbar open
let navBarOpen = ref(true)

</script>

<style scss>

</style>
