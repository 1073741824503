<template>
<q-header elevated class="glossy">
  <q-toolbar>
    <q-btn
      flat
      dense
      round
      @click="navBarOpen = !navBarOpen"
      aria-label="Menu"
      icon="menu"
    />

    <q-toolbar-title>
      Jon's Application Sandbox
    </q-toolbar-title>
    <q-btn v-if="userEmail == null" color="green" @click="login">Login</q-btn>
    <q-btn v-else color="red" @click="logout">Logout {{ userEmail }}</q-btn>
  </q-toolbar>
</q-header>
</template>

<script setup>
import { defineProps, defineEmits, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const props = defineProps({
  navBarOpen: {
    type: Boolean,
    required: true,
  },
})
const emit = defineEmits(["toggleNavBar"])
const router = useRouter()
const store = useStore()
const userEmail = computed(() => store.state.auth.userEmail)

const navBarOpen = computed({
  get() {
    return props.navBarOpen
  },
  set(value) {
    emit("toggleNavBar", value)
  }
})

// link stylesheets and scripts
onMounted(() => {
  let helloScript = document.createElement('script')
  let helloLink = document.createElement('link')
  helloScript.setAttribute("src", "https://cdn.hello.coop/js/hello-btn.js")
  helloLink.setAttribute("href", "https://cdn.hello.coop/css/hello-btn.css")
  helloLink.setAttribute("rel", "stylesheet")
  document.head.appendChild(helloScript)
  document.head.appendChild(helloLink)
})

const login = () => {
  router.push("/hello-demo")
}

const logout = () => {
  store.commit("auth/logout")
}

</script>